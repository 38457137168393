import React, { useState } from 'react'
import { FaCheck } from 'react-icons/fa'

const EmailSignup = () => {
    const [email, setEmail] = useState("");
    const [signedUp, setSignedUp] = useState(false);
    const [error, setError] = useState("");

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!email) {
            setError('Email required.');
            return false;
        } else if (!emailRegex.test(email)) {
            setError('Please enter a valid email address.');
            return false;
        } else {
            setError('');
            return true;
        }
    }

    const onEmailChange = (e) => {
        setEmail(e.target.value)
        validateEmail(e.target.value);
    }

    const registerEmail = async () => {
        const userEmail = email
        
        if (!validateEmail(userEmail)) {
            return
        }
        try {
            const response = await fetch("https://www.proximitychat.app/auth/register-email", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: "include",
                body: JSON.stringify({ email: userEmail})
            });
            if (response.ok) {
                // Request successful
                const res = await response.json();
                console.log(res);
                setSignedUp(true);
            } else {
                // Request failed
                const error = await response.json();
                console.log(error);
                setError(error);
            }
        } catch (error) {
            console.error(error);
        }
    }

    return (
        <div id="email-signup" className='w-full py-14 text-white bg-[#181818] px-6'>
            <div className='max-w-[1340px] mx-auto grid lg:grid-cols-3'>
                <div className='lg:col-span-2'>
                    <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2'>Want to become a Proximity Chat tester?</h1>
                    <p>Sign up to join the closed beta test and stay up to date.</p>
                </div>
                <div className='flex flex-col sm:flex-row items-center justify-between w-full'>
                    <input className={`p-2 flex w-full rounded-md text-black border-2 ${error ? 'border-red-500 focus:border-red-500 focus:ring-red-500' : 'border-gray-300 focus:border-gray-300'}`} type="email" placeholder="Enter Email" onChange={(e) => onEmailChange(e)}></input>
                    {
                    !signedUp? <button className='bg-[#009adf] text-black rounded-md font-medium w-[200px] ml-4 my-6 px-6 py-2' onClick={registerEmail}>Sign Up</button> : 
                    <button className='flex items-center justify-center bg-[#009adf] text-black rounded-md font-medium w-[220px] ml-4 my-6 px-4 py-2'>Registered<FaCheck className='ml-2'></FaCheck></button>
                    }
                </div>
            </div>
        </div>
    )
}

export default EmailSignup