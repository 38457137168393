import { NavBar } from './components/NavBar'
import Hero from './components/Hero';
import Showcase from './components/Showcase';
import Footer from './components/Footer';
import EmailSignup from './components/EmailSignup';

function App() {

    return (
      <div>
        <NavBar></NavBar>
        <Hero></Hero>
        <Showcase></Showcase>
        <EmailSignup></EmailSignup>
        <Footer></Footer>
  
      </div>
    );

  
}

export default App;
