
import React, { useState } from 'react';
import {AiOutlineClose, AiOutlineMenu} from 'react-icons/ai'

export const NavBar = () => {
    const [nav, setNav] = useState(false);

    const scrollToTarget = (id) => {
        const target = document.getElementById(id);
        target && target.scrollIntoView({ behavior: 'smooth' });
        closeNav();
    };

    const toggleNav = () => {
        setNav(!nav);
    }

    const closeNav = () => {
        setNav(false);
    }

    return (
        <div className="flex justify-between items-center h-24 max-w-[1340px] mx-auto px-4 text-white">
            <h1 className="w-full md:text-3xl sm:text-2xl text-xl text-[#009adf] font-semibold font-sans">ProximityChat</h1>
            <ul className="hidden uppercase md:flex">
                <li className="p-4 hover:text-[#009adf] cursor-pointer" onClick={() => scrollToTarget('home')} >Home</li>
                <li className="p-4 hover:text-[#009adf] cursor-pointer" onClick={() => scrollToTarget('showcase')} >Features</li>
                <li className="p-4 hover:text-[#009adf] cursor-pointer" onClick={() => scrollToTarget('footer')} >Contact</li>
            </ul>

            <div onClick={toggleNav} className='block md:hidden'>
                {nav ? <AiOutlineClose size={20} className='cursor-pointer'/> :<AiOutlineMenu size={20} className='cursor-pointer' />}
            </div>
            
            <div className={nav ? "fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500" : "fixed left-[-100%]"}>
                <div className={nav? 'w-full h-full fixed top-0 left-0 -z-10' : 'hidden'} onClick={closeNav} >
                </div>
                <h1 className="w-full md:text-3xl sm:text-2xl text-xl m-8 text-[#009adf] font-semibold font-sans">ProximityChat</h1>
                <ul className="uppercase p-4">
                    <li className="p-4 border-b border-gray-600 hover:text-[#009adf] cursor-pointer" onClick={() => scrollToTarget('home')} >Home</li>
                    <li className="p-4 border-b border-gray-600 hover:text-[#009adf] cursor-pointer" onClick={() => scrollToTarget('showcase')} >Features</li>
                    <li className="p-4 border-b border-gray-600 hover:text-[#009adf] cursor-pointer" onClick={() => scrollToTarget('footer')} >Contact</li>
                </ul>
            </div>
        </div>
      );
}