import React from 'react'
import PhoneAlbumImage from "../assets/showcase/phone_albums.png"
import ParisDark from "../assets/showcase/paris_dark-portrait.png"
import LondonLight from "../assets/showcase/london_light.png"
import CatChat from "../assets/showcase/cat_chat-portrait.png"
import DonsChat from "../assets/showcase/dons_chat-portrait.png"
import SearchPage from "../assets/showcase/search_page-portrait.png"
import ScrollAnimation from "react-animate-on-scroll";
import 'animate.css';


const Showcase = () => { 
    return (
        <div id="showcase" className='w-full bg-white py-16 px-10'>

            <div className='max-w-[1540px] mx-auto grid grid-cols-1 md:grid-rows-4 md:grid-cols-2 gap-8 md:gap-y-24 overflow-hidden' >

                <div className='flex flex-col md:justify-center md:order-1'>

                    <ScrollAnimation animateIn='animate__animated animate__fadeInLeftBig' animateOnce={true} offset={-100}>
                        <div>
                            <p className='text-[#009adf] font-bold'>EXPLORABLE MAP</p>
                            <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold'>Explore nearby rooms</h1>
                            <p>
                                Discover local rooms or explore spaces around the world on an interactive, explorable map. Proximity Chat allows anyone to create their own space, personalize it and control who can join by setting a range.
                            </p>
                        </div>
                    </ScrollAnimation>
                    
                    
                </div>

                <div className='flex justify-center my-auto md:order-2 '>
                    <ScrollAnimation animateIn='animate__animated animate__fadeInRightBig' animateOnce={true}>
                        <div className='table my-auto'>
                            <div className='table-cell'>
                                <img className='max-h-[750px] mx-auto' src={ParisDark} alt="paris_dark"></img>
                            </div>
                            <div className='table-cell'>
                                <img className='max-h-[750px] mx-auto' src={LondonLight} alt="london_light"></img>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
                
                <div className='flex flex-col justify-center md:order-4'>
                    <ScrollAnimation animateIn='animate__animated animate__fadeInRightBig' animateOnce={true} offset={-100}>
                        <div>
                            <p className='text-[#009adf] font-bold'>CUSTOMIZABLE PROFILE</p>
                            <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold'>Customize your profile</h1>
                            <p>
                                Express yourself your way—customize your profile picture, display name, banner color, and bio. Boost your Proximity Score to level up and unlock exciting new features!
                            </p>
                        </div>
                    </ScrollAnimation>
                    
                </div>
                <div className='flex justify-center my-auto md:order-3'>
                    <ScrollAnimation animateIn='animate__animated animate__fadeInLeftBig' animateOnce={true}>
                        <img className="max-h-[800px] my-auto" src={PhoneAlbumImage} alt="profiles"></img>
                    </ScrollAnimation>
                    
                </div>
                
                <div className='flex flex-col justify-center md:order-5'>
                    <ScrollAnimation animateIn='animate__animated animate__fadeInLeftBig' animateOnce={true} offset={-100}>
                        <div>
                            <p className='text-[#009adf] font-bold'>INSTANT MESSAGING</p>
                            <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold'>Join rooms, send messages, find friends</h1>
                            <p>Whether it's sharing cat photos or studying for exams, Proximity Chat helps you find and join rooms that match your interests. Instantly message people nearby and connect with new friends close to you!</p>
                        </div>
                    </ScrollAnimation>
                </div>

                <div className='flex justify-center my-auto md:order-6 '>
                    <ScrollAnimation animateIn='animate__animated animate__fadeInRightBig' animateOnce={true}>
                        <div className='table my-auto'>
                            <div className='table-cell'>
                                <img className='max-h-[750px] mx-auto' src={CatChat} alt="cat_chat_page"></img>
                            </div>
                            <div className='table-cell'>
                                <img className='max-h-[750px] mx-auto' src={DonsChat} alt="exam_chat_page"></img>
                            </div>
                        </div>
                    </ScrollAnimation>
                    
                </div>
                
                <div className='flex flex-col justify-center md:order-8'>
                    <ScrollAnimation animateIn='animate__animated animate__fadeInRightBig' animateOnce={true} offset={-100}>
                        <div>
                            <p className='text-[#009adf] font-bold'>SEARCH AND FILTER</p>
                            <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold'>Search for rooms</h1>
                            <p>Proximity Chat makes it easy to search nearby rooms to find exactly what you're looking for. Simply select an area on the map, then narrow your search with keywords and filters to discover the perfect spot!</p>
                        </div>
                    </ScrollAnimation>
                </div>

                <div className='md:order-7'>
                    <ScrollAnimation animateIn='animate__animated animate__fadeInLeftBig' animateOnce={true}>
                    <img className="max-h-[750px] mx-auto" src={SearchPage} alt="search_page"></img>
                    </ScrollAnimation>
                </div>
                
                
                

            </div>
            

        </div>
    )
}

export default Showcase