import { ReactTyped } from "react-typed";
import AppStoreBadge from "../assets/badges/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
import PlayStoreBadge from "../assets/badges/GetItOnGooglePlay_Badge_Web_color_English.png"

const Hero = () => {

    const scrollToTarget = (id) => {
        const target = document.getElementById(id);
        target && target.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div id="home" className='text-white'>
            <div className='max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
                <p className='text-[#009adf] font-bold p-2'> LOCATION BASED CHATROOMS</p>
                <h1 className='md:text-7xl sm:text-6xl text-4xl font-bold md:pt-1 md:pb-6'> <span className='text-[]'>Proximity</span> Chat.</h1>
                <div className='flex justify-center'>
                    <p className='md:text-4xl sm:text-3xl text-xl font-bold'>Join nearby chatrooms to</p>
                    <ReactTyped className='md:text-4xl sm:text-3xl text-xl font-bold pl-2 text-[#009adf]' 
                    strings = {["chat", "connect", "discover", "talk", "find friends"]} 
                    typeSpeed={120} backSpeed={140} loop/>
                </div>
                <p className='md:test-2xl text-xl font-bold text-gray-500 py-3'>Create and join chatrooms near your location to discover new communities and connect with others.</p>
                <div className='flex justify-center pt-2'>
                    <button> <img className='h-[40px]' src={ AppStoreBadge } alt="appstore_button" onClick={() => scrollToTarget("email-signup")}/> </button>
                    {/* <a href=''>
                        <button> <img className='h-[40px]' src={ AppStoreBadge } alt="appstore_button"/> </button>
                    </a> */}
                    <button className='pl-3'> <img className='h-[40px]' src={ PlayStoreBadge } alt="play_store_button" onClick={() => scrollToTarget("email-signup")} /> </button>
                    {/* <a href=''>
                        <button className='pl-3'> <img className='h-[40px]' src={ PlayStoreBadge } alt="play_store_button"/> </button>
                    </a> */}
                    
                </div>

            </div>
            
        </div>
    )
}

export default Hero