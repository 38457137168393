import React from 'react'
import { FaDiscord } from 'react-icons/fa'
import AppStoreBadge from "../assets/badges/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg"
import PlayStoreBadge from "../assets/badges/GetItOnGooglePlay_Badge_Web_color_English.png"

const Footer = () => {

    const scrollToTarget = (id) => {
        const target = document.getElementById(id);
        target && target.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className='border-t border-t-gray-500 text-white bg-[#000300] px-4'> 
            <div id='footer' className='max-w-[1540px] mx-auto py-10'>
                <div className="w-full mx-auto grid grid-cols-1 grid-rows-2 md:grid-cols-2 md:grid-rows-1 gap-4">
                    <div className="flex mx-auto">
                        <h2 className="text-xl sm:text-2xl md:text-3xl font-bold text-white text-nowrap">Proximity Chat</h2>
                        <img className="pl-5 pr-3 cursor-pointer" src={ AppStoreBadge } alt="appstore_button" onClick={() => scrollToTarget("email-signup")}/>
                        <img className='max-h-[40px] w-auto cursor-pointer' src={ PlayStoreBadge } alt="play_store_button" onClick={() => scrollToTarget("email-signup")}/>
                        {/* <a className='pl-5 pr-3' href='#'>
                            <img src={ AppStoreBadge } alt="appstore_button"/>
                        </a>
                        <a className="" href='#'>
                            <img className='max-h-[40px] w-auto' src={ PlayStoreBadge } alt="play_store_button"/>
                        </a> */}
                        
                    </div>

                    <div className="flex mx-auto">
                        <h3 className="w-full font-bold text-xl">admin@proximitychat.app</h3>
                        <a className='pl-6' href="https://discord.gg/mBSJP4ye9p">
                            <FaDiscord size={30}></FaDiscord>
                        </a>
                    </div >
                    
                </div>
            </div>
        </div>
    )
    
}

export default Footer